import { makeAutoObservable, runInAction, toJS } from "mobx";
import moment from "moment";
import { fusionDataApi } from "../api";
import { Workflow } from "./entities/Workflow";
import { WorkflowRunResult } from "./entities/WorkflowRunResult";
import { WorkflowQueuedFile } from "./entities/WorkflowQueuedFile";
import { BE_SOURCE_TYPES, FE_SOURCE_TYPES } from "utils/enums";
import { uiStore } from "./Store";
import _ from "lodash";

class WorkflowsStore {
  isLoading = false;
  workflows = [];
  pendingFiles = [];

  api = null;
  editableTriggers = new Set();

  //Workflow that is only used when sending the patch requests
  readOnlyWorkflow = null;
  editableOutputs = new Set();
  editableInputs = new Set();

  //Workflow that is only used when sending the patch requests
  isWorkflow = false;
  isTemplateView = false;

  constructor() {
    makeAutoObservable(this, {
      api: false,
    });

    this.api = fusionDataApi.workflows;
  }

  getWorkflows = () => {
    return toJS(this.workflows);
  };

  setIsTemplateView = (value) => {
    this.isTemplateView = value;
  };

  getIsTemplateView = () => {
    return this.isTemplateView;
  };

  setIsWorkflow = (value) => {
    this.isWorkflow = value;
  };

  getIsWorkflow = () => {
    return this.isWorkflow;
  };

  /**
   * Current workflow that is being edited etc
   */
  setReadOnlyWorkflow = (workflow) => {
    //Copy the workflow to the read only value without reference
    this.readOnlyWorkflow = _.merge({}, workflow);
  };

  getReadOnlyWorkflow = () => {
    return this.readOnlyWorkflow;
  };

  /************************************************************************
   *                        MULTIPLE OUTPUTS OPERATIONS                   *
   * Methods and logic for managing workflows that produce multiple       *
   * outputs or results.                                                  *
   ************************************************************************/

  addEditableOutput = (reportUuid) => {
    this.editableOutputs.add(reportUuid);
  };

  removeEditableOutput = (reportUuid) => {
    this.editableOutputs.delete(reportUuid);
  };

  getEditableOutputs = () => {
    return this.editableOutputs;
  };

  clearEditableOutputs = () => {
    this.editableOutputs.clear();
  };

  getReadOnlyWorkflowReports = () => {
    const {
      configuration: {
        steps: {
          "DELIVER_FILES_V1-1": {
            params: { reports = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    return reports;
  };

  getReadOnlyWorkflowReportsOutputMappingUuids = () => {
    const {
      configuration: {
        steps: {
          "DELIVER_FILES_V1-1": {
            params: { reports = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    let outputMappingUuidsList = [];
    reports.forEach((report) => {
      const { outputMappingUuid } = report;
      if (![undefined, null].includes(outputMappingUuid)) {
        outputMappingUuidsList.push(outputMappingUuid);
      }
    });

    return outputMappingUuidsList;
  };

  addClonedReportToWorkflow = (report) => {
    const {
      configuration: {
        steps: {
          "DELIVER_FILES_V1-1": {
            params: { reports = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    reports.push(report);
  };

  removeLastReportFromWorkflow = () => {
    const {
      configuration: {
        steps: {
          "DELIVER_FILES_V1-1": {
            params: { reports = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    reports.pop();
  };

  updateReadOnlyWorkflowOutput = (report, reportIndex) => {
    const {
      configuration: {
        steps: {
          "DELIVER_FILES_V1-1": {
            params: { reports = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    //If we don't have a report at that index yet it must be new.
    if (reports[reportIndex] !== undefined) {
      reports[reportIndex] = report;
    } else {
      reports.push(report);
    }
  };

  async updateWorkflowOutput(teamId, uuid, workflow) {
    const asJsonPatch = {
      name: workflow.name,
      description: workflow.description,
      configuration: toJS(workflow.configuration),
    };

    return await this.api
      .updateWorkflow(teamId, uuid, asJsonPatch)
      .then(() => {
        uiStore.addNotification("success", "Output successfully saved");
      })
      .catch((error) => {
        uiStore.addNotification("error", "Output could not be saved.");
        throw new Error(error);
      });
  }

  async saveWorkflowClonedOutput() {
    const asJsonPatch = {
      name: this.getReadOnlyWorkflow().name,
      description: this.getReadOnlyWorkflow().description,
      configuration: toJS(this.getReadOnlyWorkflow().configuration),
    };

    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Output successfully cloned");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to clone output! Please try again");

          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to clone output! Please try again");
        throw new Error(error);
      });
  }

  async deleteWorkflowOutput(reportUuid) {
    const reports = this.getReadOnlyWorkflowReports();
    const reportIndex = reports.findIndex((report) => report.reportUuid === reportUuid);
    const report = reports.splice(reportIndex, 1);

    const asJsonPatch = {
      name: this.getReadOnlyWorkflow().name,
      description: this.getReadOnlyWorkflow().description,
      configuration: toJS(this.getReadOnlyWorkflow().configuration),
    };

    const undoDelete = () => {
      reports.splice(reportIndex, 0, { ...report[0] });
    };

    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Output successfully deleted");

          return { ...response, ...{ success: true } };
        } else {
          undoDelete();
          uiStore.addNotification("error", "Unable to delete output! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to delete output! Please try again");
        throw new Error(error);
      });
  }

  /************************************************************************
   *                        MULTIPLE INPUTS OPERATIONS                    *
   * Methods and logic for managing workflows that handle multiple        *
   * input sources or parameters.                                         *
   ************************************************************************/

  addEditableInput = (importRuleUuid) => {
    this.editableInputs.add(importRuleUuid);
  };

  removeEditableInput = (importRuleUuid) => {
    this.editableInputs.delete(importRuleUuid);
  };

  getEditableInputs = () => {
    return this.editableInputs;
  };

  clearEditableInputs = () => {
    this.editableInputs.clear();
  };

  getReadOnlyWorkflowTemplates = () => {
    const {
      configuration: {
        steps: {
          "APPLY_TEMPLATE_V1-1": {
            params: { templateImportRules = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    return templateImportRules;
  };

  validateWorkflowInputSource(workflow) {
    workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules.forEach((importRule) => {
      let {
        source: { type },
      } = importRule;

      if ([BE_SOURCE_TYPES.FUSION_UI, FE_SOURCE_TYPES.FUSION_UI].includes(type)) {
        importRule.source.type = BE_SOURCE_TYPES.FUSION_UI;
      }
    });

    return workflow;
  }

  async updateWorkflowInput(teamId, uuid, workflow) {
    const validatedWorkflow = this.validateWorkflowInputSource(workflow);
    const asJsonPatch = {
      name: validatedWorkflow.name,
      description: validatedWorkflow.description,
      configuration: toJS(validatedWorkflow.configuration),
    };

    return await this.api
      .updateWorkflow(teamId, uuid, asJsonPatch)
      .then(() => {
        uiStore.addNotification("success", "Input successfully saved");
      })
      .catch((error) => {
        uiStore.addNotification("error", "Input could not be saved.");
        throw new Error(error);
      });
  }

  updateReadOnlyWorkflowInput = (importRule, importRuleIndex) => {
    const {
      configuration: {
        steps: {
          "APPLY_TEMPLATE_V1-1": {
            params: { templateImportRules = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    //If we don't have a importRule at that index yet it must be new.
    if (templateImportRules[importRuleIndex] !== undefined) {
      templateImportRules[importRuleIndex] = importRule;
    } else {
      templateImportRules.push(importRule);
    }
  };

  addClonedTemplateImportRuleToWorkflow = (importRule) => {
    const {
      configuration: {
        steps: {
          "APPLY_TEMPLATE_V1-1": {
            params: { templateImportRules = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    templateImportRules.push(importRule);
  };

  removeLastTemplateImportRuleFromWorkflow = () => {
    const {
      configuration: {
        steps: {
          "APPLY_TEMPLATE_V1-1": {
            params: { templateImportRules = [] },
          },
        },
      },
    } = this.readOnlyWorkflow;

    templateImportRules.pop();
  };

  async saveWorkflowClonedInput() {
    const validatedWorkflow = this.validateWorkflowInputSource(this.getReadOnlyWorkflow());

    const asJsonPatch = {
      name: validatedWorkflow.name,
      description: validatedWorkflow.description,
      configuration: toJS(validatedWorkflow.configuration),
    };

    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Input successfully cloned");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to clone input! Please try again");

          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to clone input! Please try again");
        throw new Error(error);
      });
  }

  async deleteWorkflowInput(importRuleUuid) {
    const templateImportRules = this.getReadOnlyWorkflowTemplates();
    const importRuleIndex = templateImportRules.findIndex((importRule) => importRule.importRuleUuid === importRuleUuid);
    const importRule = templateImportRules.splice(importRuleIndex, 1);

    const asJsonPatch = {
      name: this.getReadOnlyWorkflow().name,
      description: this.getReadOnlyWorkflow().description,
      configuration: toJS(this.getReadOnlyWorkflow().configuration),
    };

    const undoDelete = () => {
      templateImportRules.splice(importRuleIndex, 0, { ...importRule[0] });
    };

    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Input successfully deleted");

          return { ...response, ...{ success: true } };
        } else {
          undoDelete();
          uiStore.addNotification("error", "Unable to delete input! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to delete input! Please try again");
        throw new Error(error);
      });
  }

  /************************************************************************
   *                       MULTIPLE TRIGGERS OPERATIONS                   *
   * Contains methods and logic for handling workflows with multiple      *
   * trigger points or conditions.                                        *
   ************************************************************************/

  updateReadOnlyWorkflowTrigger = (trigger, triggerIndex) => {
    const {
      configuration: { triggers },
    } = this.readOnlyWorkflow;

    //If we don't have a trigger at that index yet it must be new.
    if (triggers[triggerIndex] !== undefined) {
      triggers[triggerIndex] = trigger;
    } else {
      triggers.push(trigger);
    }
  };

  addClonedTriggerToWorkflow = (trigger) => {
    const {
      configuration: { triggers = [] },
    } = this.readOnlyWorkflow;

    triggers.push(trigger);
  };

  removeLastTriggerFromWorkflow = () => {
    const {
      configuration: { triggers = [] },
    } = this.readOnlyWorkflow;

    triggers.pop();
  };

  /************************************************************************
   *                            WORKFLOW OPERATIONS                       *
   * Core methods and functionalities for managing and processing         *
   * workflows within the system.                                         *
   ************************************************************************/

  addEditableTrigger = (reportUuid) => {
    this.editableTriggers.add(reportUuid);
  };
  removeEditableTrigger = (reportUuid) => {
    this.editableTriggers.delete(reportUuid);
  };
  getEditableTriggers = () => {
    return this.editableTriggers;
  };
  clearEditableTriggers = () => {
    this.editableTriggers.clear();
  };

  /************************************************************************
   *               CURRENT WORKFLOW BEING EDITED OPERATIONS               *
   * This section includes methods and logic related to the workflow      *
   * currently under editing, configuration, or modification.             *
   ************************************************************************/

  setReadOnlyWorkflow = (workflow) => {
    //Copy the workflow to the read only value without reference
    this.readOnlyWorkflow = _.merge({}, workflow);
  };
  getReadOnlyWorkflow = () => {
    return this.readOnlyWorkflow;
  };

  getReadOnlyWorkflowTriggers = () => {
    const {
      configuration: { triggers = [] },
    } = this.readOnlyWorkflow;
    return triggers;
  };

  removeLastReportFromWorkflow = () => {
    const {
      configuration: { triggers = [] },
    } = this.readOnlyWorkflow;
    triggers.pop();
  };

  async updateWorkflowTrigger(teamId, uuid, workflow) {
    const asJsonPatch = {
      name: workflow.name,
      description: workflow.description,
      configuration: toJS(workflow.configuration),
    };
    return await this.api
      .updateWorkflow(teamId, uuid, asJsonPatch)
      .then(() => {
        uiStore.addNotification("success", "Trigger successfully saved");
      })
      .catch((error) => {
        uiStore.addNotification("error", "Trigger could not be saved.");
        throw new Error(error);
      });
  }

  async saveWorkflowClonedTrigger(teamId, uuid) {
    const asJsonPatch = {
      name: this.getReadOnlyWorkflow().name,
      description: this.getReadOnlyWorkflow().description,
      configuration: toJS(this.getReadOnlyWorkflow().configuration),
    };
    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Trigger successfully cloned");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to clone trigger! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to clone trigger! Please try again");
        throw new Error(error);
      });
  }

  async deleteWorkflowTrigger(triggerUuid) {
    const triggers = this.getReadOnlyWorkflowTriggers();
    const triggerIndex = triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid);
    const trigger = triggers.splice(triggerIndex, 1);
    const asJsonPatch = {
      name: this.getReadOnlyWorkflow().name,
      description: this.getReadOnlyWorkflow().description,
      configuration: toJS(this.getReadOnlyWorkflow().configuration),
    };
    const undoDelete = () => {
      triggers.splice(triggerIndex, 0, { ...trigger[0] });
    };
    return await this.api
      .updateWorkflow(this.getReadOnlyWorkflow().teamId, this.getReadOnlyWorkflow().uuid, asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Trigger successfully deleted");
          return { ...response, ...{ success: true } };
        } else {
          undoDelete();
          uiStore.addNotification("error", "Unable to delete trigger! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to delete trigger! Please try again");
        throw new Error(error);
      });
  }

  loadWorkflows = async (teamId) => {
    try {
      const { data, status } = await this.api.getWorkflows(teamId);

      if (status === 200) {
        this.workflows = data.map((workflow) => new Workflow(this, workflow));
        return { success: true, data: this.workflows };
      } else {
        uiStore.addNotification("error", "Error loading workflows.");
        return { success: false, data: [] };
      }
    } catch (error) {
      uiStore.addNotification("error", `Error loading workflows. ${error}`);
      return { success: false, data: [] };
    }
  };

  loadSourceWorkflows = async (teamId, workflowUuid) => {
    try {
      const { data, status } = await this.api.getSourceWorkflows(teamId, workflowUuid);

      if (status === 200) {
        return { success: true, data };
      } else {
        uiStore.addNotification("error", "Error loading source workflows.");
        return { success: false, data: [] };
      }
    } catch (error) {
      uiStore.addNotification("error", `Error loading source workflows. ${error}`);
      return { success: false, data: [] };
    }
  };

  loadDestinationWorkflows = async (teamId, workflowUuid) => {
    try {
      const { data, status } = await this.api.getDestinationWorkflows(teamId, workflowUuid);

      if (status === 200) {
        return { success: true, data };
      } else {
        uiStore.addNotification("error", "Error loading destination workflows.");
        return { success: false, data: [] };
      }
    } catch (error) {
      uiStore.addNotification("error", `Error loading destination workflows. ${error}`);
      return { success: false, data: [] };
    }
  };

  async removeWorkflowInclusionRule(workflow, teamId, workflowUuid, template) {
    const patch = {
      name: workflow.name,
      description: workflow.description,
      configuration: workflow.configuration,
    };
    return await this.api
      .updateWorkflow(teamId, workflowUuid, patch)
      .then(({ status }) => {
        if (status === 200) {
          uiStore.addNotification("success", `Template '${template.templateName}' successfully removed`);
          return { ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to remove template '${template.templateName}'! Please try again`);

          return { ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to remove template '${template.templateName}'! Please try again`);
      });
  }

  async addWorkflowTemplate(workflow, teamId, workflowUuid) {
    const patch = {
      name: workflow.name,
      description: workflow.description,
      configuration: workflow.configuration,
    };
    return await this.api
      .updateWorkflow(teamId, workflowUuid, patch)
      .then(({ status, data }) => {
        if (status === 200) {
          uiStore.addNotification("success", "Template successfully added");
          return { ...{ success: true }, ...{ data: data } };
        } else {
          uiStore.addNotification("error", "Unable to add template! Please try again");

          return { ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", "Unable to add template! Please try again");
      });
  }

  async removeWorkflowTemplate(workflow, teamId, workflowUuid) {
    const patch = {
      name: workflow.name,
      description: workflow.description,
      configuration: workflow.configuration,
    };
    return await this.api
      .updateWorkflow(teamId, workflowUuid, patch)
      .then(({ status, data }) => {
        if (status === 200) {
          uiStore.addNotification("success", "Template successfully removed");
          return { ...{ success: true }, ...{ data: data } };
        } else {
          uiStore.addNotification("error", "Unable to remove template! Please try again");

          return { ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", "Unable to remove template! Please try again");
      });
  }

  async editWorkflowTemplate(workflow, teamId, workflowUuid) {
    const patch = {
      name: workflow.name,
      description: workflow.description,
      configuration: workflow.configuration,
    };
    return await this.api
      .updateWorkflow(teamId, workflowUuid, patch)
      .then(({ status, data }) => {
        if (status === 200) {
          uiStore.addNotification("success", "Changes successfully saved");
          return { ...{ success: true }, ...{ data: data } };
        } else {
          uiStore.addNotification("error", "Unable to save changes! Please try again");

          return { ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", "Unable to save changes! Please try again");
      });
  }

  async createNewWorkflow(teamId, workflowData) {
    this.isLoading = true;

    return await this.api
      .createWorkflow(teamId, workflowData)
      .then(({ data }) => {
        runInAction(() => {
          this.workflows.push(new Workflow(this, data));
          this.isLoading = false;
          uiStore.addNotification("success", "Workflow created!");
        });

        return data;
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Workflow could not be created. ${error}`);
      });
  }

  async deleteWorkflow(teamId, workflowUuid, name) {
    this.isLoading = true;

    return await this.api
      .deleteWorkflow(teamId, workflowUuid)
      .then((response) => {
        this.isLoading = false;

        const { status } = response;

        if ([200, 204].includes(status)) {
          uiStore.addNotification("success", `Workflow ${name} successfully deleted.`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete workflow ${name}! Please try again.`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Workflow could not be deleted. ${error}`);
      });
  }

  getWorkflowByUuid(uuid) {
    const workflow = this.workflows.filter((workflow) => workflow.uuid === uuid);
    return workflow[0];
  }

  getWorkflowName(uuid) {
    const { name = "" } = this.getWorkflowByUuid(uuid) || {};
    return name;
  }

  getWorkflowsByTeamId(teamId) {
    return this.workflows.filter((workflow) => workflow.teamId === teamId);
  }

  getWorkflowEntityByUuidAndTeamId = (workflowUuid, teamId) => {
    return this.api
      .getWorkflowByUuid(teamId, workflowUuid)
      .then((response) => {
        return { workflow: new Workflow(this, response.data), ...{ success: true } };
      })
      .catch(() => {
        uiStore.addNotification("error", "Unable to fetch Workflow.");
        return { ...{ success: false } };
      });
  };

  getWorkflowByUuidAndTeamId = (workflowUuid, teamId) => {
    return this.api
      .getWorkflowByUuid(teamId, workflowUuid)
      .then((response) => {
        return { ...response, success: response.status === 200 };
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to fetch Workflow. ${error}`);
      });
  };

  /************************************************************************
   *                     WORKFLOW RUN RESULT OPERATIONS                   *
   * This section contains methods and logic related to handling the      *
   * results of workflow runs.                                            *
   ************************************************************************/

  async getRunResults(teamId, date) {
    this.isLoading = true;

    const startTimestamp = moment(date).startOf("day").valueOf();
    const endTimestamp = moment(date).endOf("day").valueOf();

    return await this.api
      .getRunResults(teamId, startTimestamp, endTimestamp)
      .then(({ data, status }) => {
        this.isLoading = false;

        if (status === 200) {
          return data.map((runResult) => new WorkflowRunResult(this, teamId, runResult));
        } else {
          uiStore.addNotification("error", "Error loading run results.");
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Error loading run results. ${error}`);
        this.isLoading = false;
      });
  }

  async getRunResultsByWorkflowUuid(teamId, workflowUuid, date) {
    this.isLoading = true;

    const startTimestamp = moment(date).startOf("day").valueOf();
    const endTimestamp = moment(date).endOf("day").valueOf();

    return await this.api
      .getRunResultsByWorkflowUuid(teamId, workflowUuid, startTimestamp, endTimestamp)
      .then(({ data, status }) => {
        this.isLoading = false;

        if (status === 200) {
          return data.map((runResult) => new WorkflowRunResult(this, teamId, runResult));
        } else {
          uiStore.addNotification("error", "Error loading run results.");
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Error loading run results. ${error}`);
        this.isLoading = false;
      });
  }

  /**
   * Rerun a specified workflow based on workflowrun uuid.
   *
   * @param {number} teamId - The team's ID.
   * @param {string} workflowRunUuid - The UUID of the workflow run.
   * @returns {Promise<{success: boolean}>} - Result of the operation.
   */
  rerunWorkflowFromRun(teamId, workflowRunUuid) {
    this.isLoading = true;

    const notificationId = uiStore.addNotification("status", "Running workflow...");

    return this.api
      .rerunWorkflowFromRun(teamId, workflowRunUuid)
      .then((response) => {
        this.isLoading = false;

        uiStore.removeNotification(notificationId);

        if (response.status === 200) {
          uiStore.addNotification("success", "Run successfully completed.");
          return { success: true };
        } else {
          uiStore.addNotification("error", "Unable to run the workflow because it has no valid templates. Please add or update templates and try again.");
          return { success: false };
        }
      })
      .catch((error) => {
        this.isLoading = false;

        uiStore.addNotification("error", `Error rerunning workflow: ${error}`);
        return { success: false };
      });
  }

  /**
   * Requeues files for the specified workflow run.
   *
   * @param {number} teamId - The team's ID.
   * @param {string} workflowRunUuid - The UUID of the workflow run.
   * @returns {Promise<{success: boolean}>} - Result of the operation.
   */
  requeueFilesForWorkflowRun(teamId, workflowRunUuid) {
    this.isLoading = true;

    return this.api
      .requeueFilesForWorkflowRun(teamId, workflowRunUuid)
      .then((response) => {
        this.isLoading = false;

        if (response.status === 200) {
          uiStore.addNotification("success", "Files successfully restored to queue.");
          return { success: true };
        } else {
          uiStore.addNotification("error", "Unable to requeue files as the workflow has no valid templates. Please add or update templates and try again.");
          return { success: false };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Error requeuing files: ${error}`);
        return { success: false };
      });
  }

  async getRunResultFileData(fileUuid) {
    return await this.api
      .getRunResultFile(fileUuid)
      .then(({ data }) => data)
      .catch((error) => uiStore.addNotification("error", `Error loading run result file. ${error}`));
  }

  async getConnectionSummary(type, method) {
    return await fusionDataApi.fusionAdmin
      .connectionSummary(type, method)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => uiStore.addNotification("error", `Error getting connection details. ${error}`));
  }

  /*************************************************************************
   *                     WORKFLOW QUEUED FILE OPERATIONS                   *
   * This section contains all methods related to managing queued files    *
   * within the workflow.                                                  *
   ************************************************************************/

  async getPendingFiles(teamId, workflowUuid) {
    this.isLoading = true;

    try {
      const response = await this.api.getPendingFiles(teamId, workflowUuid);
      const { data, status } = response;

      if (status === 200) {
        this.pendingFiles = data.map((pendingFiles) => new WorkflowQueuedFile(this, teamId, pendingFiles));

        return { success: true };
      } else {
        uiStore.addNotification("error", "Error loading queued files.");
        return { success: false };
      }
    } catch (error) {
      uiStore.addNotification("error", "Error loading queued files.");
      return { success: false };
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Computed property that transforms pending files into grid rows for the queue
   */
  get queueFileData() {
    // Transform the pending files array into rows for the grid
    // Each file can have multiple templates, so we create a row for each file-template combination
    return (this.pendingFiles || []).reduce((rows, file) => {
      // Destructure file properties
      const { fileName = "", fileUuid = "", workflowQueuedFileId = "", createdDate = null, templatesAssigned = [] } = file || {};

      // If file has no templates, create one row with empty template name
      const templates = templatesAssigned?.length ? templatesAssigned : [{ appliedTemplateName: "" }];

      // Add a row to our array for each template
      templates.forEach((template) => {
        rows.push({
          fileName,
          fileUuid,
          workflowQueuedFileId,
          appliedTemplateName: template?.appliedTemplateName ?? "",
          appliedTemplateUuid: template?.appliedTemplateUuid ?? "",
          createdDate,
        });
      });

      return rows;
    }, []);
  }

  async assignTemplateToQueuedFile(teamId, workflowUuid, workflowQueuedFileId, templateUuids) {
    this.isLoading = true;

    try {
      const { status } = await this.api.assignTemplateToQueuedFile(teamId, workflowUuid, workflowQueuedFileId, templateUuids);

      if (status === 200) {
        await this.getPendingFiles(teamId, workflowUuid);
        uiStore.addNotification("success", "Template successfully assigned");
        return { success: true };
      } else {
        uiStore.addNotification("error", "Unable to assign template! Please try again");
        return { success: false };
      }
    } catch (error) {
      uiStore.addNotification("error", "Unable to assign template! Please try again");
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  triggerPendingFiles(teamId, workflowUuid, workflowQueuedFileIds, workflowName) {
    this.isLoading = true;

    return this.api
      .triggerWorkflowForPendingFiles(teamId, workflowUuid, workflowQueuedFileIds)
      .then((response) => {
        this.isLoading = false;

        const { status } = response;

        if ([200, 202].includes(status)) {
          uiStore.addNotification("success", `Workflow '${workflowName}' successfully completed a run`);
          return { ...response, success: true };
        } else {
          uiStore.addNotification("error", `Workflow '${workflowName}' failed to run! Please try again`);
          return { ...response, success: false };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Workflow '${workflowName}' failed to run: ${error}`);
      });
  }

  removePendingFiles(teamId, workflowUuid, workflowQueuedFileIds) {
    this.isLoading = true;

    return this.api
      .removeFromPendingFileQueue(teamId, workflowUuid, workflowQueuedFileIds)
      .then((response) => {
        this.isLoading = false;

        const { status } = response;

        if ([200, 202].includes(status)) {
          uiStore.addNotification("success", "Files successfully removed from queue");
          return { ...response, success: true };
        } else {
          uiStore.addNotification("error", "Unable to remove files from queue! Please try again.");
          return { ...response, success: false };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `File(s) could not be removed. ${error}`);
      });
  }
}

export { WorkflowsStore };
