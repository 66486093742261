import { useEffect } from "react";
import { useStore } from "store/Store";

/**
 * Manages and filters template data for a workflow.
 * Includes checks to prevent errors when components render before data is ready.
 *
 * @param {Object} workflow - Workflow object with teamId and template settings
 * @returns {Object} Filtered list of templates
 */
const useTemplates = (workflow) => {
  const { templatesStore, workflowsStore } = useStore();
  let listOfTemplates = [];

  /**
   * Loads templates for the specified team when workflow changes
   */
  useEffect(() => {
    templatesStore.loadTemplates(workflow.teamId);
  }, [workflow?.teamId, templatesStore]);

  /**
   * Wait for workflow store to be ready
   */
  if (!workflowsStore.readOnlyWorkflow) {
    return { listOfTemplates: [] };
  }

  /**
   * Gets template import rules from workflow store and extracts unique template UUIDs
   * @returns {Array} Array of unique template UUIDs
   */
  const templateImportRules = workflowsStore.getReadOnlyWorkflowTemplates();
  const templateUuids = [...new Set(templateImportRules.map((importRule) => importRule.inclusionRules?.map((rule) => rule.templateUuid)).flat())];

  /**
   * Filters store templates to only include those referenced in the workflow
   * @returns {Array} Filtered list of template objects
   */
  listOfTemplates = templatesStore.getTemplates().filter((template) => templateUuids.includes(template.templateUuid));

  return { listOfTemplates };
};

export { useTemplates };
