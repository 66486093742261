import { DropdownListItem, DropdownItem, CheckableInput } from "@fundrecs/ui-library";
import { v4 as uuidv4 } from "uuid";

const TemplateListItem = ({ template, isSelected, onSelect }) => {
  const idSuffix = uuidv4();

  const { templateUuid, name } = template;

  return (
    <DropdownListItem className={`dropdown-checkable ${isSelected ? "dropdownItem-selected" : "dropdownItem-default"}`}>
      <DropdownItem index={templateUuid}>
        <CheckableInput
          className="mr-12"
          type="checkbox"
          onChange={onSelect}
          id={`checkbox_${templateUuid}_${idSuffix}`}
          name={name}
          label={name}
          checked={isSelected}
        />
      </DropdownItem>
    </DropdownListItem>
  );
};

export { TemplateListItem };
