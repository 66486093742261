import { useState } from "react";

/**
 * Custom hook for managing template selection state and associated display text
 * @param {Array} listOfTemplates - Array of template objects containing {templateUuid, name}
 * @returns {Object} Selection handlers and state management functions
 */
const useTemplateSelection = (listOfTemplates) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [displayText, setDisplayText] = useState("");

  /**
   * Converts array of selected template UUIDs into comma-separated string of template names
   * @param {Array} selectedTemplates - Array of selected template UUIDs
   * @returns {string} Comma-separated string of template names
   */
  const generateDisplayText = (selectedTemplates) => {
    if (!selectedTemplates.length) return "";
    return listOfTemplates
      .filter((template) => selectedTemplates.includes(template.templateUuid))
      .map((template) => template.name)
      .join(", ");
  };

  /**
   * Handles checkbox selection/deselection of a template
   * @param {string} templateUuid - UUID of the template being toggled
   * @returns {Function} Event handler for checkbox change
   */
  const handleSelectedTemplateUuid =
    (templateUuid) =>
    ({ target: { checked } }) => {
      const isChecked = checked;
      const updatedSelectedTemplates = isChecked ? [...selectedTemplates, templateUuid] : selectedTemplates.filter((uuid) => uuid !== templateUuid);

      setSelectedTemplates(updatedSelectedTemplates);
      setDisplayText(generateDisplayText(updatedSelectedTemplates));
    };

  /**
   * Resets selection state by clearing selected templates and display text
   */
  const handleClearSelection = () => {
    setSelectedTemplates([]);
    setDisplayText("");
  };

  return {
    selectedTemplates,
    setSelectedTemplates,
    displayText,
    setDisplayText,
    handleSelectedTemplateUuid,
    handleClearSelection,
    generateDisplayText,
  };
};

export { useTemplateSelection };
