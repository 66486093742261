import { Dropdown, DropdownButton, DropdownButtonText, ItemBody, DropdownList, IconDownArrow, Text } from "@fundrecs/ui-library";
import { TemplateListItem } from "./TemplateListItem";

export const TemplateDropdown = ({
  selectedTemplates,
  displayText,
  listOfTemplates,
  handleSelectedTemplateUuid,
  handleClearSelection,
  disabled = false,
  dropdownProps = {},
  buttonProps = {},
}) => {
  return (
    <Dropdown {...dropdownProps}>
      <DropdownButton size="sm" disabled={disabled} data-bs-auto-close="*" {...buttonProps}>
        <DropdownButtonText>
          <ItemBody>{selectedTemplates.length === 0 ? <span style={{ color: "#838B9C" }}>Select</span> : <span>{displayText}</span>}</ItemBody>
          <IconDownArrow className="btn-sm-svg" />
        </DropdownButtonText>
      </DropdownButton>
      <DropdownList>
        {listOfTemplates.map((template) => (
          <TemplateListItem
            key={template.templateUuid}
            template={template}
            isSelected={selectedTemplates.includes(template.templateUuid)}
            onSelect={handleSelectedTemplateUuid(template.templateUuid)}
          />
        ))}
        <hr className="m-0" />
        <div
          className="clearSelection"
          onClick={(e) => {
            e.stopPropagation();
            handleClearSelection();
          }}
        >
          <Text size="sm">Clear selection</Text>
        </div>
      </DropdownList>
    </Dropdown>
  );
};
