import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Button, Modal, ModalHeader, ModalText, ModalFooter, Input, modalInstance, LoadingButton } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { LOADING_STATES, FILE_SIZE } from "utils/enums";
import { useTemplates } from "components/workflows/hooks/useTemplates";
import { useTemplateSelection } from "components/workflows/hooks/useTemplateSelection";
import { TemplateDropdown } from "./reusable/TemplateDropdown";

// set delay time for closing modal once file is succesfully uploaded
const MODAL_CLOSE_DELAY = 2000;

// set delay time for resetting submit button state after an error
const ERROR_RESET_DELAY = 5000;

const getLoadingButtonText = (loadingState) => {
  switch (loadingState) {
    case LOADING_STATES.LOADING:
      return "Uploading...";

    case LOADING_STATES.LOADED:
      return "Uploaded";

    case LOADING_STATES.ERROR:
      return "Failed to upload!";

    default:
      return "Upload file";
  }
};

const UploadFileModal = observer(({ workflow, modalId, onSuccess, onError }) => {
  const { workflowsStore } = useStore();
  const fileInputRef = useRef();

  // File state
  const [file, setFile] = useState();
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATES.IDLE);

  // Get workflow entity from store
  const workflowEntity = workflowsStore.getWorkflowByUuid(workflow.uuid);

  // Use our hooks for template management
  const { listOfTemplates } = useTemplates(workflow);
  const { selectedTemplates, displayText, handleSelectedTemplateUuid, handleClearSelection } = useTemplateSelection(listOfTemplates);

  // Derived UI state
  const fileIsInvalid = file && file.size > FILE_SIZE.MAX_BYTES;
  const uploadEnabled = selectedTemplates.length > 0 && file && !fileIsInvalid;
  const inputsDisabled = loadingStatus !== LOADING_STATES.IDLE;
  const loadingButtonText = getLoadingButtonText(loadingStatus);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const resetUploadForm = () => {
    // reset display text and selected templates
    handleClearSelection();

    // reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    // reset file state
    setFile(null);
  };

  const handleClose = () => {
    // close modal
    modalInstance(modalId).hide();

    // reset upload form
    resetUploadForm();
    setLoadingStatus(LOADING_STATES.IDLE);
  };

  const handleUpload = () => {
    //Adding the setting of state to a timeout lets the dropdown close now first, please refer to bugfix/FS-1486
    setTimeout(() => setLoadingStatus(LOADING_STATES.LOADING), 500);

    const json = JSON.stringify(selectedTemplates);
    const blob = new Blob([json], {
      type: "application/json",
    });

    workflowEntity
      .uploadFile(file, blob)
      .then(() => {
        setLoadingStatus(LOADING_STATES.LOADED);

        // callback to main view that upload has succeeded
        onSuccess();

        // reset display text
        // add short delay before closing modal
        // so user sees button state change to success
        setTimeout(handleClose, MODAL_CLOSE_DELAY);
      })
      .catch(() => {
        setLoadingStatus(LOADING_STATES.ERROR);

        // callback to main view that upload has failed
        onError();

        // clear the error state after a short delay and enable re-submission
        setTimeout(() => setLoadingStatus(LOADING_STATES.IDLE), ERROR_RESET_DELAY);
      });
  };

  return (
    <Modal modalId={modalId}>
      <div className="d-flex flex-column modal-body">
        <ModalHeader heading="Upload a file (max. size 15 MB)" />
        <ModalText text="Your workflow will start a run as soon as this file has been uploaded if its source is “file upload” and it has an automated run trigger." />
      </div>
      <div className="pt-16 pl-32 pr-32">
        <Text size="sm" weight="medium" variant="secondary">
          Select file to upload
        </Text>
        <div className="pt-8">
          <Input
            onChange={handleFileChange}
            type="file"
            placeholderText="No file chosen"
            size="md"
            warningMessage={FILE_SIZE.MAX_WARNING}
            warning={fileIsInvalid}
            innerRef={fileInputRef}
            disabled={inputsDisabled}
          />
        </div>

        <div className="pt-20 mb-8 pb-32">
          <Text size="sm" weight="medium" variant="secondary">
            Select template(s) to apply to file
          </Text>
          <div className="pt-8">
            <TemplateDropdown
              selectedTemplates={selectedTemplates}
              displayText={displayText}
              listOfTemplates={listOfTemplates}
              handleSelectedTemplateUuid={handleSelectedTemplateUuid}
              handleClearSelection={handleClearSelection}
              disabled={inputsDisabled}
            />
          </div>
        </div>
      </div>

      <ModalFooter>
        <Button color="tertiary" onClick={handleClose} disabled={inputsDisabled}>
          <Text size="sm">Cancel</Text>
        </Button>
        <LoadingButton status={loadingStatus} onClick={handleUpload} disabled={!uploadEnabled}>
          <Text size="sm">{loadingButtonText}</Text>
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
});

export { UploadFileModal };
