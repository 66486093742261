import { useState, useEffect } from "react";
import { useParams, useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore, uiStore } from "store/Store";
import { Text, Button, BorderBottom, modalInstance, VerticalMenuDropdown, VerticalMenuListItem } from "@fundrecs/ui-library";
import { StatusCell } from "../../ag-grid/StatusCell/StatusCell";
import { ManageLayout, PageTitleArea } from "components/layout/Layout";
import { WorkflowSettings } from "./modals/WorkflowSettings";
import { MODAL_IDS } from "utils/workflows/enums";
import { ConfigurationTab } from "./tabs/ConfigurationTab";
import { ResultsTab } from "./tabs/ResultsTab";
import { Tabs } from "./tabs/Tabs";
import { DeleteWorkflowModal } from "./modals/DeleteWorkflow";
import { UploadFileModal } from "./modals/UploadFileModal";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES, TABS } from "utils/enums";
import { QueueTab } from "./tabs/QueueTab";
import { useTeamId } from "store/hooks/useTeamId";

import styles from "./WorkflowDetail.module.scss";

// set delay time for refreshing results once a run starts
const AUTO_REFRESH_DELAY = 7000;

const WorkflowDetail = observer(() => {
  const { workflowUuid } = useParams();
  const { workflowsStore, rolesStore, meStore } = useStore();
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [searchParams] = useSearchParams();
  const [runResults, setRunResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [date, setDate] = useState(searchParams.get("date") ? new Date(searchParams.get("date")) : new Date());
  const teamId = useTeamId();

  const fetchRunResults = async () => {
    setLoading(true);

    // Fetch run results
    const results = await workflowsStore.getRunResultsByWorkflowUuid(teamId, workflowUuid, date);
    setRunResults(results);

    setLoading(false);
  };

  useEffect(() => {
    if (teamId) {
      workflowsStore.getWorkflowEntityByUuidAndTeamId(workflowUuid, teamId).then((response) => {
        const { success, workflow } = response;

        if (success) {
          setWorkflow(workflow);
          workflowsStore.setReadOnlyWorkflow(workflow);
          if (!workflow) {
            // Redirect back to workflows list
            navigate({ pathname: "/workflows", search: `?${createSearchParams({ teamId })}` });
          } else {
            workflowsStore.getPendingFiles(teamId, workflowUuid);
          }
        } else {
          const team = meStore.getTeamById(teamId);
          if (team === undefined) {
            modalInstance(MODAL_IDS.ERROR_TEAM_NOT_FOUND).show();
          } else {
            modalInstance(MODAL_IDS.ERROR_WORKFLOW_NOT_FOUND).show();
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, workflowUuid]);

  const handleUploadSuccess = () => {
    fetchRunResults();
    workflowsStore.getPendingFiles(teamId, workflowUuid);

    // show "in progress" toast message
    const notificationId = uiStore.addNotification("status", `Uploaded file for "${workflow.name}" to be picked up by trigger configuration...`);

    // after a short delay, reload the Run results and pending file queue and close the toast
    setTimeout(() => {
      fetchRunResults();
      workflowsStore.getPendingFiles(teamId, workflowUuid);
      uiStore.removeNotification(notificationId);
    }, AUTO_REFRESH_DELAY);
  };

  const handleUploadError = (error) => {
    uiStore.addNotification("error", `Failed to upload file. ${error}`);
  };

  const handleDeleteWorkflow = async (teamId, uuid, name) => {
    const response = await workflowsStore.deleteWorkflow(teamId, uuid, name);
    const { success } = response;
    if (success) {
      workflowsStore.loadWorkflows(teamId);
      navigate({ pathname: "/workflows", search: `?${createSearchParams({ teamId: workflow?.teamId })}` });
    }
  };

  const handleRemovePendingFiles = async (teamId, workflowQueuedFileIds) => {
    const response = await workflowsStore.removePendingFiles(teamId, workflowUuid, workflowQueuedFileIds);
    if (response?.success) {
      workflowsStore.getPendingFiles(teamId, workflowUuid);
    }
  };

  const handleRunPendingFiles = async (teamId, workflowQueuedFileIds) => {
    const notificationId = uiStore.addNotification("status", `Running workflow "${workflow.name}"`);

    const response = await workflowsStore.triggerPendingFiles(teamId, workflowUuid, workflowQueuedFileIds, workflow.name);
    uiStore.removeNotification(notificationId);

    if (response?.success) {
      workflowsStore.getPendingFiles(teamId, workflowUuid);
    }
  };

  return (
    <>
      {workflow && (
        <>
          <UploadFileModal workflow={workflow} modalId={MODAL_IDS.UPLOAD_FILE} onSuccess={handleUploadSuccess} onError={handleUploadError} />
          <WorkflowSettings workflow={workflow} />
          <DeleteWorkflowModal workflow={workflow} modalId={MODAL_IDS.DELETE_WORKFLOW} handleDeleteWorkflow={handleDeleteWorkflow} />
          <PageTitleArea
            leftArrow={true}
            title={workflow.name}
            navigate={() => {
              navigate({ pathname: "/workflows", search: `?${createSearchParams({ teamId: workflow?.teamId })}` });
            }}
            description={
              <div className="text-regular">
                <StatusCell value={workflow.status} />
              </div>
            }
            borderBottom={false}
          >
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_EDIT])}>
              <span className="pr-12">
                <Button
                  color="primary-secondary"
                  size="md"
                  onClick={() => {
                    navigate({ pathname: `/workflows/${workflowUuid}/configure`, search: `?${createSearchParams({ teamId: workflow?.teamId })}` });
                  }}
                >
                  <Text size="sm">Edit workflow</Text>
                </Button>
              </span>
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  modalInstance(MODAL_IDS.UPLOAD_FILE).toggle();
                }}
              >
                <Text size="sm">Upload a file</Text>
              </Button>
            </AuthWrapper>
            <span className="pl-12" />
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_EDIT])}>
              <div className={styles.circle}>
                <VerticalMenuDropdown>
                  <VerticalMenuListItem
                    text="Settings"
                    onClick={() => {
                      modalInstance(MODAL_IDS.EDIT_WORKFLOW_SETTINGS).toggle();
                    }}
                  />
                  <VerticalMenuListItem
                    text="Delete"
                    onClick={() => {
                      modalInstance(MODAL_IDS.DELETE_WORKFLOW).toggle();
                    }}
                  />
                </VerticalMenuDropdown>
              </div>
            </AuthWrapper>
          </PageTitleArea>
          <Tabs activeTabKey={activeTabKey} setActiveTabKey={setActiveTabKey} />
          <ManageLayout>
            {activeTabKey === TABS.RESULTS.key && (
              <ResultsTab loading={loading} getRunResultData={fetchRunResults} runResults={runResults} date={date} setDate={setDate} />
            )}
            {activeTabKey === TABS.QUEUE.key && (
              <QueueTab
                workflow={workflow}
                handleRemovePendingFiles={handleRemovePendingFiles}
                handleRunPendingFiles={handleRunPendingFiles}
                activeTabKey={activeTabKey}
              />
            )}
            {activeTabKey === TABS.OVERVIEW.key && <ConfigurationTab workflow={workflow} />}
          </ManageLayout>
          <BorderBottom />
        </>
      )}
    </>
  );
});

export { WorkflowDetail };
