import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Text, Button, Modal, ModalHeader, ModalFooter, ModalSection, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { useTemplates } from "components/workflows/hooks/useTemplates";
import { useTemplateSelection } from "components/workflows/hooks/useTemplateSelection";
import { TemplateDropdown } from "./reusable/TemplateDropdown";

const AssignTemplateModal = observer(({ workflow, modalId, workflowQueuedFileId }) => {
  const { workflowsStore, uiStore } = useStore();
  const [isAssigning, setIsAssigning] = useState(false);
  const teamId = useTeamId();

  const { listOfTemplates } = useTemplates(workflow);
  const { selectedTemplates, setSelectedTemplates, displayText, setDisplayText, handleSelectedTemplateUuid, handleClearSelection, generateDisplayText } =
    useTemplateSelection(listOfTemplates);

  // When modal opens, set the dropdown's initial value if queued file has a template assigned
  useEffect(() => {
    // Get reference to modal DOM element
    const modalElement = document.getElementById(modalId);

    // Return early if modal is not mounted
    if (!modalElement) return;

    const handleShow = () => {
      // Check for existing template assignment
      const { templateUuid } = modalElement.dataset;
      if (templateUuid) {
        const initialDisplayText = generateDisplayText([templateUuid]);
        setSelectedTemplates([templateUuid]);
        setDisplayText(initialDisplayText);
      } else {
        handleClearSelection();
      }
      // Reset dataset for next open
      modalElement.dataset.templateUuid = "";
    };

    // Add listener for Bootstrap's modal show event and clean up on unmount
    modalElement.addEventListener("show.bs.modal", handleShow);
    return () => modalElement.removeEventListener("show.bs.modal", handleShow);
  }, [modalId, generateDisplayText, setSelectedTemplates, setDisplayText, handleClearSelection]);

  const handleClose = () => {
    modalInstance(modalId).hide();
  };

  const handleAssign = async () => {
    if (isAssigning) return;

    try {
      setIsAssigning(true);
      await workflowsStore.assignTemplateToQueuedFile(teamId, workflow.uuid, workflowQueuedFileId, selectedTemplates);
      handleClose();
    } catch (error) {
      uiStore.addNotification("error", `Failed to assign template: ${error.message || "Please try again"}`);
    } finally {
      setIsAssigning(false);
    }
  };

  const assignEnabled = selectedTemplates.length > 0;

  return (
    <Modal modalId={modalId}>
      <div className="d-flex flex-column modal-body">
        <div className="d-flex mr-0">
          <ModalHeader heading="Assign template" />
          <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} />
        </div>
        <ModalSection classes="mb-0 pb-0">
          <Text size="sm" weight="medium" variant="secondary">
            Select template(s) from your workflow to assign to file
          </Text>
        </ModalSection>
      </div>

      <div className="pl-32 pr-32 pb-32">
        <TemplateDropdown
          selectedTemplates={selectedTemplates}
          displayText={displayText}
          listOfTemplates={listOfTemplates}
          handleSelectedTemplateUuid={handleSelectedTemplateUuid}
          handleClearSelection={handleClearSelection}
        />
      </div>

      <ModalFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button color={isAssigning ? "tertiary" : "primary"} onClick={handleAssign} disabled={!assignEnabled || isAssigning}>
          <Text size="sm">{isAssigning ? "Assigning..." : "Assign"}</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { AssignTemplateModal };
